<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="交易明细">
            </el-page-header>
        </div>
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form class="searchbox" :model="searchForm" :inline="true">
                            <el-row :gutter="20">
                                <el-col :span="20">
                                    <el-row :gutter="20">
                                        <el-col :span="4">
                                            <div class="item-img">
                                                <img v-if="commodityStatisticsNum.commodity.coverPicture.length > 0"
                                                    :src="Services.Shopping + '/' + commodityStatisticsNum.commodity.coverPicture[0].filePath">
                                                <img v-else src="../../../../assets/img/no-img.png" />
                                            </div>
                                        </el-col>
                                        <el-col :span="20">
                                            <div class="item-title">{{ commodityStatisticsNum.commodity.name }}</div>
                                            <div class="item-title">{{ commodityStatisticsNum.commodity.category.name }}
                                            </div>
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="4">
                                    <div class="item-title" v-if="commodityStatisticsNum.commodity.state == 1">
                                        <el-tag size="small" type="success">上架中</el-tag>
                                    </div>
                                    <div class="item-title" v-else>
                                        <el-tag size="small" type="info">未上架</el-tag>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row :gutter="20" style="margin-top:10px">
                                <!-- <el-col :span="6">库存总数：{{ commodityStatisticsNum.stockNum }}</el-col> -->
                                <el-col :span="6">剩余库存：{{ commodityStatisticsNum.surplusNum }}</el-col>
                                <el-col :span="6">已售订单数：{{ commodityStatisticsNum.soldNum }}</el-col>
                                <el-col :span="6">实到金额：{{ commodityStatisticsNum.merchantActualMoney }}</el-col>
                            </el-row>
                            <el-divider></el-divider>
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-form-item style="margin-bottom:0">
                                        <el-input v-model="searchForm.keyWord" type="text" size="small"
                                            placeholder="输入关键词搜索..." @keyup.enter.native="SearchInputKeydown">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" size="small" @click="search()">搜索</el-button>
                                    </el-form-item>
                                    <el-form-item>
                                        <download-excel v-if="tableData.length>0" :data="tableData"
                                            :fields="json_fields" worksheet="My Worksheet" :header="`${commodityStatisticsNum.commodity.name}_${title}`"
                                            :name="`${commodityStatisticsNum.commodity.name}_${title}.xls`" class="excel">
                                            <el-button type="warning" size="mini" class="el-icon-download">导出当前页数据
                                            </el-button>
                                        </download-excel>
                                    </el-form-item>
                                    <el-form-item>
                                        <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                            worksheet="My Worksheet" :header="`${commodityStatisticsNum.commodity.name}_${title}`" :name="`${commodityStatisticsNum.commodity.name}_${title}_All.xls`"
                                            class="excel">
                                            <el-button type="danger" size="mini" class="el-icon-download">导出{{DataCount}}条数据
                                            </el-button>
                                        </download-excel>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 255px)" :header-cell-style="{background:'#F5F7FC'}">
                    <!-- <el-table-column prop="order.addressee" label="购买人">
                        <template slot-scope="data">
                            <p>{{ data.row.userName }}</p>
                            <p>{{ data.row.phone }}</p>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="sonOrderNumber" label="订单编号" width="240" fixed></el-table-column>
                    <el-table-column label="订单信息">
                        <template slot-scope="scope">
                            <div class="orderprovos-box">
                                ￥{{ scope.row.actualMoney }}*{{ scope.row.commodityNum }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="freight" label="快递费（元）" align="center">
                    </el-table-column>
                    <el-table-column prop="payMoney" label="实付金额（元）" align="center">
                    </el-table-column>
                    <!-- <el-table-column prop="freight" label="分享佣金" align="center">
                    </el-table-column>
                    <el-table-column prop="totalSharedMoney" label="分销金额" align="center">
                    </el-table-column>
                    <el-table-column prop="freight" label="分红金额" align="center">
                    </el-table-column>
                    <el-table-column prop="platformCommissionMoney" label="平台抽佣" align="center">
                    </el-table-column> -->
                    <!-- <el-table-column prop="merchantActualMoney" label="实到金额" align="center">
                    </el-table-column> -->
                    <el-table-column label="订单状态" align="center">
                        <template slot-scope="scope">
                            <div class="orderprovos-box">
                                <el-tag v-if="scope.row.state == 0" type="warning" size="small">待付款</el-tag>
                                <el-tag v-if="scope.row.state == 1" type="success" size="small">已完成</el-tag>
                                <el-tag v-if="scope.row.state == 2" size="small">待发货</el-tag>
                                <el-tag v-if="scope.row.state == 3" type="primary" size="small">已发货</el-tag>
                                <el-tag v-if="scope.row.state == -1" type="info" size="small">已取消</el-tag>
                                <el-tag v-if="scope.row.state == -2" type="danger" size="small">已删除</el-tag>
                                <el-tag v-if="scope.row.state == -999" type="danger" size="small">退款</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="下单时间" align="center">
                        <template slot-scope="scope"> {{ scope.row.order.createTime | timefilters }} </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { STATS } from "../../../../components/HospitalDomain/STATS"
export default {
    name: "SalesDetail",
    data() {
        var statistics = new STATS(this.TokenClient, this.Services.Shopping)
        return {
            StatisticsDomain: statistics,
            commodityId: this.$route.query.commodityId,
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            searchForm: {
                Keyword: '',
            },
            Keyword: '',
            tableData: [],
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            commodityStatisticsNum: {
                commodity: {
                    coverPicture: [],
                    category: { name: "" }
                }
            },
            title: '商品订单统计',
            DataCount:0,
            json_fields: {
                "订单编号": {
                    filed: 'sonOrderNumber',
                    callback: val => {
                        return 'NO：' + val.sonOrderNumber
                    }
                },
                "订单信息": {
                    callback: val => {
                        return `￥${val.actualMoney}*${val.commodityNum}`
                    }
                },
                "快递费": 'freight',
                "实付金额": 'payMoney',
                "订单状态": {
                    callback: val => {
                        var str = ''
                        var val = val.state
                        switch (val) {
                            case 0:
                                str = '待付款'
                                break;
                            case 1:
                                str = '已完成'
                                break;
                            case 2:
                                str = '待发货'
                                break;
                            case 3:
                                str = '已发货'
                                break;
                            case -1:
                                str = '已取消'
                                break;
                            case -2:
                                str = '已删除'
                                break;
                            case -999:
                                str = '退款'
                                break;
                        }
                        return str
                    }
                },
                "下单时间": {
                    filed: 'createTime',
                    callback: (val) => {
                        var val = val.order.createTime
                        return this.$fliters.timefilters(val)
                    }
                },
            },
        }
    },
    mounted() {
        this.getCommodityStatisticsNum()
        this.getList()
    },
    methods: {
        formatOrderInfo(val) {
            var res = ''
            val.forEach((item, i) => {
                res += `【${item.actualMoney}*${item.commodityNum}】`
            })
            return res
        },
        getCommodityStatisticsNum() {
            this.StatisticsDomain.CommodityStatisticsNum(this.commodityId,
                (data) => {
                    this.commodityStatisticsNum = data.data
                }, (err) => {
                    console.log(err);
                })
        },
        search() {
            this.PageIndex = 1;
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                _this.StatisticsDomain.SalesDetails(_this.commodityId, 1, _this.searchForm.keyWord, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (err) {
                        console.log(err);
                        resolve('');
                    });
            })

        },
        getList() {
            this.StatisticsDomain.SalesDetails(this.commodityId, this.PageIndex, this.searchForm.keyWord, null,
                (data) => {
                    this.tableData = data.data.results;
                    this.PageIndex = data.data.pageIndex;
                    this.PageSize = data.data.pageSize;
                    this.PageTotal = data.data.pageCount;
                    this.DataTotal = data.data.dataTotal;
                        this.DataCount = data.data.dataTotal;
                }, (err) => {
                    console.log(err);
                })
        },
        goBack() {
            this.$router.go(-1);
        },
    }
}
</script>

<style scoped>

.el-page-header {
    line-height: 73px;
    padding-left: 20px;
}

/*content*/


.item-img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    overflow: hidden;
}

.item-img img {
    width: 100%;
}

.item-title {
    height: 40px;
    line-height: 40px;
}

.searchbox .el-input {
    width: 300px;
}
</style>
